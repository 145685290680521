<template>
  <v-container fluid class="main-container">
    <div id="rotate-to-portrait">
      <span class="rotate-to-portrait-text">{{ $t('trial-screen.rotate') }}</span>
    </div>
    <v-row>
      <v-col cols="12" sm="10" md="10" lg="10" xl="10" class="information-reminder">
        <v-fade-transition>
          <div id="reminder-modal" class="modal" v-if="showReminderModal">
            <div class="modal-reminder-content">
              <span class="close" @click="showReminderModal = false" @keyup.enter="showReminderModal = false">&times;
              </span>
              <v-row>
                <v-col class="mb-0 pb-0" cols="12" md="3" justify="left">
                  <v-img
                    class="modal-logo mb-0 pb-0"
                    :src="require('../assets/images/patient_up_logo.png')"
                    :contain="true" />
                </v-col>
              </v-row>
              <v-row>
                <hr class="gradient-line" />
              </v-row>
              <v-row>
                <h3 class="modal-reminder-title">{{ $t('reminder-title') }}</h3>
              </v-row>
              <v-row>
                <div class="modal-reminder-text">
                  <ul>
                    <li>
                      {{ $t('reminder-msg-1') }}
                    </li>
                    <li>
                      {{ $t('reminder-msg-2') }}
                    </li>
                    <li>
                      {{ $t('reminder-msg-3') }}
                    </li>
                  </ul>
                </div>
                <v-row justify="center" class="reminder-button-container">
                  <v-btn class="modal-reminder-button" x-large @click="reminderAccepted()">
                    {{ $t('reminder-accept') }}
                  </v-btn>
                </v-row>
              </v-row>
            </div>
          </div>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row class="main-screen">
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-img src="../assets/images/patient3.png" class="responsive-image" :contain="false" />
      </v-col>
      <v-col cols="12" md="6">
        <v-row cols="12" class="language-icons">
          <locale-switcher />
        </v-row>
        <v-row cols="12">
          <v-col cols="6" />
          <v-col cols="6">
            <v-img class="sermes-logo" :src="require('../assets/images/patient_up_logo.png')" :contain="true" />
          </v-col>
        </v-row>
        <v-row class="right-side">
          <v-col cols="12" class="pb-0 mx-0 px-0 my-0 py-0">
            <v-card id="trial-information-form-card" class="form-card">
              <v-card-title primary-title class="justify-left pt-0" v-if="trialExpired == false">
                <h3 id="trial-information-form-title" class="step-title">
                  {{ $t('trial-screen.title') }}
                </h3>
              </v-card-title>
              <v-card-text v-if="trialExpired == false">
                <v-form id="trial-information-form" ref="form" v-model="valid" lazy-validation>
                  <v-textarea
                    ref="inicioTextarea"
                    class="trialInformationText"
                    v-model="localizedTrialInformation"
                    color="teal"
                    @scroll="scrolled"
                    @mousedown.prevent
                    @selectstart.prevent
                    readonly />
                  <div class="info-checkbox">
                    <v-checkbox
                      v-if="instructions"
                      :class="[
                        instrRdDisabled ? 'trial-instruccions checkbox-disabled'
                        : 'trial-instruccions checkbox-enabled',
                        formSubmitted && !instrRd ? 'highlight-red' : '',
                      ]"
                      v-model="instrRd"
                      :disabled="instrRdDisabled">
                      <template v-slot:label>
                        <div :class="formSubmitted && !instrRd ? 'highlight-red-text' : ''" class="inline-elements">
                          <span>{{ $t('trial-screen.read') }}</span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                :class="formSubmitted && !instrRd ? 'highlight-red-text'
                                  : 'trial-a inline-element'"
                                target="_blank"
                                :href="instructions"
                                @click.stop
                                v-on="on"
                                rel="noopener noreferrer">
                                {{ $t('trial-screen.trial-instructions') }}
                              </a>
                            </template>
                            {{ $t('trial-screen.trial-instructions') }}
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      id="term-and-conditions"
                      :class="[
                        agreementDisabled ? 'trial-instruccions checkbox-disabled'
                        : 'trial-instruccions checkbox-enabled',
                        formSubmitted && !agree ? 'highlight-red' : '',
                      ]"
                      v-model="agree"
                      :disabled="agreementDisabled">
                      <template v-slot:label>
                        <div :class="formSubmitted && !agree ? 'highlight-red-text' : ''" class="inline-elements">
                          <span>{{ $t('trial-screen.agree') }}</span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                :class="formSubmitted && !agree ? 'highlight-red-text' : 'trial-a inline-element'"
                                target="_blank"
                                :href="termsAndConditionsUrl"
                                @click.stop
                                v-on="on"
                                rel="noopener noreferrer">
                                {{ $t('trial-screen.term-conditions') }}
                              </a>
                            </template>
                            {{ $t('trial-screen.term-conditions') }}
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      id="privacy-policy"
                      :class="[
                        privacyPolicyDisabled ? 'trial-instruccions checkbox-disabled'
                        : 'trial-instruccions checkbox-enabled',
                        formSubmitted && !privacyPolicy ? 'highlight-red' : '',
                      ]"
                      v-model="privacyPolicy"
                      :disabled="privacyPolicyDisabled">
                      <template v-slot:label>
                        <div
                          :class="formSubmitted && !privacyPolicy ? 'highlight-red-text' : ''"
                          class="inline-elements">
                          <span>{{ $t('trial-screen.agree2') }}</span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                :class="formSubmitted && !privacyPolicy ? 'highlight-red-text' : 'trial-a inline-element'"
                                target="_blank"
                                :href="privacyPolicyUrl"
                                @click.stop
                                v-on="on"
                                rel="noopener noreferrer">
                                {{ $t('trial-screen.privacy-policy-details') }}
                              </a>
                            </template>
                            {{ $t('trial-screen.privacy-policy-details') }}
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-center pt-0 info-card-action" v-if="trialExpired == false">
                <button
                  id="patient-form-next-button"
                  x-large
                  :class="checkValidForm
                    ? 'button-enabled'
                    : 'button-disabled'"
                  @click="nextStep()"
                  @dblclick="preventDoubleClick"
                  type="button">
                  {{ $t('trial-screen.button-next') }}
                </button>
              </v-card-actions>
              <v-card-text v-if="trialExpired">
                <h1 class="trial-expired">{{ $t('trial-screen.expired') }}</h1>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import { trialServices } from '../services/trialService';
import LocaleSwitcher from './LocaleSwitcher.vue';
import getFirebaseConfig from '../utils/firebaseConfig';
import store from '../store/store';
import { coreServices } from '../services/coreService';

export default {
  name: 'TrialInformation',
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      trial: '',
      trialExpired: false,
      step: 1,
      icons: ['mdi-information ', 'mdi-account', 'mdi-medical-bag', 'mdi-doctor'],
      trialInformation: '',
      trialName: '',
      shortTrialName: '',
      valid: false,
      studyRead: false,
      instructions: '',
      instrRd: false,
      agree: false,
      agreementDisabled: true,
      privacyPolicy: false,
      privacyPolicyDisabled: true,
      instrRdDisabled: true,
      showReminderModal: false,
      formSubmitted: false,
      localizedTrialInformation: '',
      termsAndConditionsUrl: coreServices.getTermsAndConditionsUrl(),
      privacyPolicyUrl: coreServices.getPrivacyPolicyUrl(),
    };
  },
  created() {
    console.log(coreServices.getTermsAndConditionsUrl());
    this.trial = this.$route.params.trialId;
    this.$watch('$i18n.locale', this.updateLocalizedInformation);
    trialServices.getTrialInformation(this.trial)
      .then((response) => {
        if (response === undefined) {
          store.dispatch('trialDataLoaded');
          this.$router.push('/404');
        } else {
          const currentDate = new Date();
          let hasExpired = false;
          if (response.trial_expire_date) {
            const trialExpireDate = new Date(response.trial_expire_date.seconds * 1000);
            hasExpired = trialExpireDate <= currentDate;
          }
          if (hasExpired === true) {
            this.trialExpired = true;
            store.dispatch('trialDataLoaded');
          } else {
            if (response.attachment_file) {
              this.instructions = response.attachment_file;
            } else {
              this.instrRd = true;
            }
            this.trialExpired = false;
            if (response.trial_info.spanish === undefined) {
              this.trialInformation = this.trialInformation.replace(/(\\r)*\\n/g, String.fromCharCode(13, 10));
              this.trialInformation = JSON.parse(response.trial_info);
            } else {
              this.trialInformation = response.trial_info;
            }

            this.trialName = response.trial_name;
            this.shortTrialName = response.short_trial_name;
            this.updateLocalizedInformation();
            store.dispatch('trialDataLoaded');
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.addEvent('first_visit', 'Trial Information view');
  },
  watch: {
    '$i18n.locale': 'updateLocalizedInformation',
  },
  computed: {
    ...mapGetters(['selectedLanguage']),
    checkValidForm() {
      return this.agree && this.studyRead && this.instrRd && this.privacyPolicy;
    },
  },
  methods: {
    async addEvent(eventName, passName) {
      /* eslint-disable no-underscore-dangle */
      window._paq = window._paq || [];
      window._paq.push(['trackEvent', eventName, eventName, eventName, passName]);
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        const analytics = getAnalytics(app);
        logEvent(analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    updateLocalizedInformation() {
      this.localizedTrialInformation = this.$i18n.locale === 'en'
        ? this.trialInformation.english : this.trialInformation.spanish;
    },
    nextStep() {
      if (!this.checkValidForm) {
        this.formSubmitted = true;
        this.openReminderModal();
        return;
      }
      this.step += 1;
      this.$emit('update', { step: this.step, trialName: this.trialName });
      this.addEvent('step1_next_buton', 'Step1 Next Button');
    },
    scrolled() {
      this.openReminderModal();
      const textareaElement = [...this.$refs.inicioTextarea.$el.getElementsByTagName('textarea')][0];
      const { scrollHeight, clientHeight: height, scrollTop } = textareaElement;
      const maxScroll = scrollHeight - height;
      if (Math.abs(maxScroll - scrollTop) <= 5) {
        this.studyRead = true;
        this.agreementDisabled = false;
        this.instrRdDisabled = false;
        this.privacyPolicyDisabled = false;
      }
    },
    openReminderModal() {
      this.showReminderModal = true;
    },
    reminderAccepted() {
      const modal = document.getElementById('reminder-modal');
      modal.style.display = 'none';
    },
    preventDoubleClick(event) {
      event.preventDefault();
    },
  },
};
</script>
<style scoped>
#rotate-to-portrait {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(249, 254, 254, 1);
  color: var(--highlightedPU);
  text-align: center;
  z-index: 10000;
  /* Asegura que se muestra sobre todo lo demás */
  align-items: center;
  justify-content: center;
}

.rotate-to-portrait-text {
  width: 70%;
  line-height: 2em;
  font-size: 1.4em;
  font-weight: bold;
}

/* Base Styles */
.main-container {
  font-size: 1em;
}

.trial-expired {
  margin-top: 10vh;
  margin-left: 10%;
  font-family: 'Roboto-Regular';
  font-size: 1.7em;
  text-align: left;
  width: 70%;
  line-height: 7vh;
  color: var(--highlightedPU);
}

.sermes-image {
  width: 100% !important;
}

.step-title {
  margin-top: 0;
  font-family: 'Roboto-Regular';
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#patient-form-next-button {
  min-height: 45px;
}

.trialInformationText {
  white-space: pre;
  font-family: 'Roboto-Regular';
}

.trial-a {
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--highlightedPU) !important;
  text-decoration: none;
}

.right-side {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

.form-card {
  margin: 0;
  padding: 0;
  box-shadow: none !important;
}

button {
  text-transform: uppercase;
}

.inline-elements * {
  display: inline;
}

.main-screen {
  height: 100vh;
}

/* Textarea Styles */
.trialInformationText>>>textarea {
  resize: none !important;
  cursor: auto;
}

.trialInformationText>>>textarea::-webkit-scrollbar {
  width: 10px;
}

.trialInformationText>>>textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.trialInformationText>>>textarea::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #13c5e0, #2e90e0);
  border-radius: 5px;
}

.trialInformationText>>>textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Modal Styles */
#reminder-modal {
  padding-top: 5vh !important;
}

.modal {
  display: block;
  position: fixed;
  height: 100vh;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  background-color: rgba(18, 197, 224, 0.4);
}

.information-reminder {
  position: absolute;
  z-index: 99;
  text-align: left;
}

.modal-reminder-content {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.gradient-line {
  margin: auto;
  width: 90%;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
}

.close {
  margin-top: -12px;
  margin-bottom: 0;
  color: var(--typographyPU);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-reminder-title {
  color: var(--highlightedPU) !important;
  font-size: 1.5em;
  margin-left: 10%;
}

.modal-reminder-button {
  text-align: center;
  font-family: 'Roboto-Bold';
  font-size: 1.1em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

.modal-reminder-text {
  height: calc(100vh - 10vh - 15vh - 25vh);
  width: 85%;
  margin-top: 1vh;
  margin-bottom: 3vh;
  margin-left: 7%;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1.2em;
  line-height: 2em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-reminder-text ul {
  list-style-type: none;
  padding-left: 0;
}

.modal-reminder-text ul li {
  position: relative;
  padding-left: 1.5em;
  margin-top: 0px;
}

.modal-reminder-text ul li::before {
  content: "\2022";
  color: var(--highlightedPU);
  position: absolute;
  left: 0;
}

.modal-remind .highlight-red .v-input--selection-controls__input .v-icon {
  color: red !important;
}

.highlight-red-text {
  color: red;
}

.checkbox-enabled>>>.v-label {
  font-family: 'Roboto-Bold';
  color: var(--typographyPU);
}

.trial-instruccions {
  font-family: 'Roboto-Bold';
  font-weight: bold;
  background: none !important;
  color: var(--highlightedPU) !important;
}

.trial-instruccions:first-of-type {
  padding-top: 2vh;
}

#patient-form-next-button {
  font-family: 'Roboto-Bold';
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
  ;
}

.button-enabled {
  border: solid var(--highlightedPU) 3px;
  color: var(--highlightedPU);
  opacity: 1;
}

.button-disabled {
  cursor: not-allowed ! important;
  border: solid var(--nonHighlightedTextPU) 3px;
  color: var(--nonHighlightedTextPU);
  opacity: 0.45;
}

.checkbox-enabled {
  opacity: 1;
  margin-top: -10px;
  font-family: 'Roboto-Bold';
  background: none !important;
  color: var(--highlightedPU) !important;
}

.checkbox-disabled {
  opacity: 0.5;
  margin-top: -10px;
  font-family: 'Roboto-Bold';
  background: none !important;
  color: var(--nonHighlightedTextPU) !important;
}

.step-counter {
  font-family: 'Roboto-Bold';
  font-weight: bold;
  font-size: 16px;
  background: none !important;
  color: var(--highlightedPU) !important;
}

.step-instructions {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  color: var(--nonHighlightedTextPU) !important;
}

.checkbox-icon {
  background: none !important;
  color: var(--highlightedPU) !important;
}

/* Para pantallas grandes */
@media (min-width: 1441px) {
  .responsive-image {
    height: 100vh;
  }

  .language-icons {
    height: 5vh;
  }

  .sermes-logo {
    height: 10vh;
  }

  .step-title {
    font-size: 1em;
    height: 5vh;
  }

  .info-checkbox {
    height: 15vh;
  }

  .info-card-action {
    height: 15vh;
    padding-bottom: 7vh !important;
  }

  .trialInformationText>>>textarea {
    resize: none !important;
    height: calc(100vh - 5vh - 10vh - 5vh - 15vh - 15vh - 10vh);
    /*-10vh margen*/
    cursor: auto;
  }

  #patient-form-next-button {
    width: 30%;
  }

  /* Modal */
  #reminder-modal {
    padding-top: 5vh !important;
    height: 100vh;
  }

  .modal-reminder-content {
    width: 60%;
    height: 70vh;
  }

  .modal-logo {
    height: 10vh;
  }

  .modal-reminder-title {
    height: 10vh;
    padding-top: 3vh;
  }

  .reminder-button-container {
    padding-top: 5vh;
    height: 25vh;
  }

  .modal-reminder-button {
    text-align: center;
    width: 25%;
    margin-top: -30vh;
    /* Ajuste para compensar height 70vh */
    height: 45px !important;
  }

  .modal-reminder-text {
    height: calc(100vh - 5vh - 10vh - 10vh -25vh - 10vh);
    /* -10vh margen */
  }
}

/* Para pantallas medianas-grandes */
@media (min-width: 1025px) and (max-width: 1440px) {
  .responsive-image {
    height: 100vh;
  }

  .language-icons {
    height: 5vh;
  }

  .sermes-logo {
    height: 10vh;
  }

  .step-title {
    font-size: 1em;
    height: 5vh;
  }

  .info-checkbox {
    height: 15vh;
  }

  .info-card-action {
    height: 15vh;
    padding-bottom: 7vh !important;
  }

  .trialInformationText>>>textarea {
    resize: none !important;
    height: calc(100vh - 5vh - 10vh - 5vh - 15vh - 15vh - 10vh);
    /*-10vh margen*/
    cursor: auto;
  }

  #patient-form-next-button {
    width: 30%;
  }

  /* Modal */
  #reminder-modal {
    padding-top: 5vh !important;
    height: 100vh;
  }

  .modal-reminder-content {
    width: 55%;
    height: 70vh;
  }

  .modal-logo {
    height: 10vh;
  }

  .modal-reminder-title {
    height: 10vh;
    padding-top: 3vh;
  }

  .reminder-button-container {
    padding-top: 5vh;
    height: 25vh;
  }

  .modal-reminder-button {
    text-align: center;
    width: 35%;
    margin-top: -30vh;
    /* Ajuste para compensar height 70vh */
    height: 45px !important;
  }

  .modal-reminder-text {
    height: calc(100vh - 5vh - 10vh - 10vh -25vh - 10vh);
    /* -10vh margen */
  }
}

/* Para tabletas y pantallas más pequeñas */
@media (min-width: 961px) and (max-width: 1024px) {
  .responsive-image {
    height: 100vh;
  }

  .language-icons {
    height: 5vh;
  }

  .sermes-logo {
    height: 10vh;
  }

  .step-title {
    font-size: 1em;
    height: 5vh;
  }

  .info-checkbox {
    height: 15vh;
  }

  .info-card-action {
    height: 15vh;
    padding-bottom: 7vh !important;
  }

  .trialInformationText>>>textarea {
    resize: none !important;
    height: calc(100vh - 5vh - 10vh - 5vh - 15vh - 15vh - 10vh);
    /*-10vh margen*/
    cursor: auto;
  }

  #patient-form-next-button {
    width: 30%;
  }

  /* Modal */
  #reminder-modal {
    padding-top: 5vh !important;
    height: 100vh;
  }

  .modal-reminder-content {
    width: 60%;
    height: 70vh;
  }

  .modal-logo {
    height: 10vh;
  }

  .modal-reminder-title {
    height: 10vh;
    padding-top: 3vh;
  }

  .reminder-button-container {
    padding-top: 5vh;
    height: 25vh;
  }

  .modal-reminder-button {
    text-align: center;
    width: 40%;
    margin-top: -30vh;
    /* Ajuste para compensar height 70vh */
    height: 45px !important;
  }

  .modal-reminder-text {
    height: calc(100vh - 5vh - 10vh - 10vh -25vh - 10vh);
    /* -10vh margen */
  }
}

/* Para tabletas y pantallas más pequeñas */
@media (min-width: 769px) and (max-width: 960px) {
  .responsive-image {
    height: 15vh;
  }

  .language-icons {
    height: 5vh;
  }

  .sermes-logo {
    height: 10vh;
  }

  .step-title {
    font-size: 1em;
    height: 5vh;
  }

  .info-checkbox {
    height: 15vh;
  }

  .info-card-action {
    height: 15vh;
    padding-bottom: 7vh !important;
  }

  .trialInformationText>>>textarea {
    resize: none !important;
    height: calc(100vh - 15vh - 5vh - 10vh - 5vh - 15vh - 15vh - 10vh);
    /*-10vh margen*/
    cursor: auto;
  }

  #patient-form-next-button {
    width: 30%;
  }

  /* Modal */
  #reminder-modal {
    padding-top: 5vh !important;
    height: 100vh;
  }

  .modal-reminder-content {
    width: 65%;
    height: 70vh;
  }

  .modal-logo {
    height: 10vh;
  }

  .modal-reminder-title {
    height: 10vh;
    padding-top: 3vh;
  }

  .reminder-button-container {
    padding-top: 5vh;
    height: 25vh;
  }

  .modal-reminder-button {
    text-align: center;
    width: 45%;
    margin-top: -30vh;
    /* Ajuste para compensar height 70vh */
    height: 45px !important;
  }

  .modal-reminder-text {
    height: calc(100vh - 5vh - 10vh - 10vh -25vh - 10vh);
    /* -10vh margen */
  }
}

/* Para dispositivos móviles en modo paisaje */
@media (min-width: 481px) and (max-width: 768px) {
  .responsive-image {
    height: 15vh;
  }

  .language-icons {
    height: 5vh;
  }

  .sermes-logo {
    height: 10vh;
  }

  .step-title {
    font-size: 1em;
    height: 5vh;
  }

  .info-checkbox {
    height: 15vh;
  }

  .info-card-action {
    height: 15vh;
    padding-bottom: 7vh !important;
  }

  .trialInformationText>>>textarea {
    resize: none !important;
    height: calc(100vh - 15vh - 5vh - 10vh - 5vh - 15vh - 15vh - 10vh);
    /*-10vh margen*/
    cursor: auto;
  }

  #patient-form-next-button {
    width: 30%;
  }

  /* Modal */
  #reminder-modal {
    padding-top: 5vh !important;
    height: 100vh;
  }

  .modal-reminder-content {
    width: 85%;
    height: 70vh;
  }

  .modal-logo {
    height: 10vh;
  }

  .modal-reminder-title {
    height: 10vh;
    padding-top: 3vh;
  }

  .reminder-button-container {
    padding-top: 5vh;
    height: 25vh;
  }

  .modal-reminder-button {
    text-align: center;
    width: 45%;
    margin-top: -25vh;
    /* Ajuste para compensar height 70vh */
    height: 45px !important;
  }

  .modal-reminder-text {
    height: calc(100vh - 5vh - 10vh - 10vh -25vh - 10vh);
    /* -10vh margen */
  }
}

/* Para dispositivos móviles en modo retrato */
@media (max-width: 480px) {
  .responsive-image {
    height: 15vh;
  }

  .language-icons {
    height: 5vh;
  }

  .sermes-logo {
    height: 6vh;
    top: -2vh;
  }

  .step-title {
    font-size: 1em;
    height: 5vh;
  }

  .info-checkbox {
    height: 15vh;
  }

  .info-card-action {
    height: 15vh;
    padding-bottom: 2vh !important;
  }

  .trialInformationText>>>textarea {
    resize: none !important;
    height: calc(100vh - 15vh - 5vh - 5vh - 5vh - 15vh - 15vh - 10vh);
    /*-10vh margen*/
    cursor: auto;
  }

  #patient-form-next-button {
    width: 60%;
  }

  /* Modal */
  #reminder-modal {
    padding-top: 5vh !important;
    height: 100vh;
  }

  .modal-reminder-content {
    width: 95%;
    height: 80vh;
  }

  .modal-logo {
    height: 10vh;
  }

  .modal-reminder-title {
    height: 10vh;
    padding-top: 3vh;
    font-size: 1.1em;
  }

  .reminder-button-container {
    padding-top: 5vh;
    height: 25vh;
  }

  .modal-reminder-button {
    text-align: center;
    font-size: 1em;
    width: 55%;
    margin-top: -15vh;
    /* Ajuste para compensar height 70vh */
    height: 40px !important;
  }

  .modal-reminder-text {
    height: calc(100vh - 5vh - 10vh - 10vh -25vh - 20vh);
    /* -10vh margen */
    font-size: 0.9em;
  }

  .checkbox-enabled {
    font-size: 0.9em !important;
  }

  .checkbox-disabled {
    font-size: 0.9em !important;
  }
}

/* Tablets landscape */
@media screen and (min-width: 1024px) and (orientation: landscape) {

  /* Modal */
  #reminder-modal {
    padding-top: 5vh !important;
    height: 100vh;
  }

  .modal-reminder-content {
    width: 60%;
    height: 65vh;
  }

  .modal-logo {
    height: 10vh;
  }

  .modal-reminder-title {
    height: 10vh;
    padding-top: 3vh;
  }

  .reminder-button-container {
    padding-top: 5vh;
    height: 30vh;
  }

  .modal-reminder-button {
    text-align: center;
    width: 35%;
    margin-top: -12vh;
    /* Ajuste para compensar height 70vh */
    height: 45px !important;
  }

  .modal-reminder-text {
    height: calc(100vh - 5vh - 10vh - 10vh - 30vh - 10vh);
    /* -10vh margen */
  }
}

/* Estilos para móviles en landscape */
@media screen and (max-width: 1023px) and (orientation: landscape) {
  #rotate-to-portrait {
    display: flex;
  }
}
</style>
